<template>
  <div></div>
</template>

<script>
import { postData } from "@/api/user";
import { mapActions } from "vuex";

export default {
  name: "auth",
  data() {
    return {};
  },
  created() {
    let query = this.$route.query;

    if (query.code) {
      postData({
        url: "api/get_userinfo",
        data: {
          code: query.code,
        },
      }).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          this.handleLogin(res.data.data);
          // location.href = "/home";

          let url = sessionStorage.getItem("beforeUrl");
          this.$router.push(url); //跳转
        }
      });
    }
  },
  methods: {
    ...mapActions(["handleLogin"]),
  },
};
</script>

<style>
</style>